document.addEventListener("DOMContentLoaded", () => {
  const flashNotifications = Array.from(document.getElementsByClassName('flash'));

  flashNotifications.forEach((el) => {
    const closeButton = el.getElementsByClassName('close')[0];

    closeButton.addEventListener('click', () => {
      el.remove();
    });
  });
});
