document.addEventListener("DOMContentLoaded", () => {
  const confirmables = document.querySelectorAll("[data-confirm]");

  confirmables.forEach((el) => {
    el.addEventListener('click', (e) => {
      const data = el.dataset;

      if (data.confirmIf && data.confirmIf === "false") {
        return;
      }

      if (!confirm(data.confirm)) {
        e.preventDefault();
      }
    });
  })
});
